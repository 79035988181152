@import "variables";
@import "bootstrap";
@import "fonts";

// Si vous ne voulez pas charger la version personnalisée de Bootstrap, utilisez simplement la ligne suivante.
@import "~bootstrap/scss/bootstrap";

// Icône Bootstrap
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

// DPK CURSOR
@import "dpk_cursor/dpkCursor.min.css";

// @import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

  @font-face {
    font-family: 'Wulkan';
    src: url('../fonts/WulkanDisplayLight.ttf') format('truetype');
    font-weight: 100;
    font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLight';
  src: url('../fonts/HelveticaNeueLight.otf') format('opentype');
  font-display: swap;
  // letter-spacing: -3rem;
}

// @font-face {
//   font-family: 'NeueMontreal-Medium';
//   src: url('../fonts/NeueMontreal-Medium.otf') format('opentype');
//   // font-display: swap;
//   letter-spacing: -3rem;
// }


@media (max-width: 768px) {
    .dpk-cursor {
      display: none;
    }
  }
  


body {
  font-family: 'HelveticaNeueLight', sans-serif;
  // font-weight: 400; /* Texte normal */
}

// p {
//   font-family: 'Arthura', sans-serif;
// }

h1 {
  // font-family: 'HelveticaNeueLight', sans-serif;
  font-family: 'Wulkan', sans-serif;

}

h2 {
  font-family: 'HelveticaNeueLight', sans-serif;
  // font-weight: 700; /* Texte en gras */
}

// body {
//         font-family: 'NeueMontreal-Light', sans-serif;
//         letter-spacing: 0.1em;
//       }






html,
body {
    overflow-y: auto;
}

.container-fluid {
    margin: 0;
    padding: 0;
}

i {
    padding: 0.5rem;
}

ul {
    list-style-type: none;
    padding-left: 0;
    color: $light;
}


a {
    text-decoration: none;
    color: $dark;
}

a:hover {
    text-decoration: none;
    color: $dark;
}

.container-fluid {
    padding: 0px;
}

// SOUND

.sound-toggle {
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    // z-index: 300;
    display: inline-block;
  }
  
  .sound-toggle span {
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .sound-toggle .sound-on {
    transform: translateY(100%);
  }
  
  .sound-toggle.on .sound-on {
    transform: translateY(0);
  }
  
  .sound-toggle .sound-off {
    transform: translateY(0);
  }
  
  .sound-toggle.on .sound-off {
    transform: translateY(-100%);
  }
  


// NAV

nav, .row {
    width: 100vw;
    margin: 0;
    padding: 0;
  }
  
//   #nav-links {
//     position: relative;
//     // z-index: 301;
//   }
  
//   .nav-index {
//     position: sticky;
//     top: 0;
//     left: 0;
//     width: 100%;
//     z-index: 1000;
//   }
  
  #navbar {
    height:8vh;
  }
  
// GRADIENT

#gradient {
    height: 4vh;
    width: 100%;
    position: fixed; /* Toujours fixe, ne bouge pas au scroll */
    top: 8vh; /* Ajuste selon la hauteur réelle de ta navbar */
    left: 0;
    z-index: 99; /* Juste sous la navbar */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none; /* Ne bloque pas les clics sur la page */
  }
  
  
  
  // BOUTON LANGAGE
  
  .lang-switch {
    cursor: pointer;
  }
  
  // FONT
  
  .small {
    font-size: 0.7em;
  }
  


/* DPK */

.dpkCursor {
    align-items: center;
    border: 2px solid #fff;
    border-radius: 5rem;
    color: #fff;
    display: flex;
    height: 1.75rem;
    justify-content: center;
    left: 0;
    mix-blend-mode: difference;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: width 0.25s cubic-bezier(0.51, 1.07, 1, 1),
      height 0.25s cubic-bezier(0.51, 1.07, 1, 1),
      background-color 0.1s cubic-bezier(0.51, 1.07, 1, 1);
    width: 1.75rem;
    z-index: 9999;
  }
  .dpkCursor.hover-active {
    font-size: 0.875rem;
    height: 6rem;
    width: 6rem;
  }
  .dpkCursor.hover-bg {
    border-width: 0;
    mix-blend-mode: normal;
  }
  .dpkCursor.noCursor {
    border-width: 0;
  }
  .dpkCursor.emogy {
    border-color: #d3d3d3;
    font-size: 2rem;
    height: 4rem;
    mix-blend-mode: normal;
    width: 4rem;
  }
  .dpkCursor.mixBlend {
    background: #fff;
    height: 4rem;
    mix-blend-mode: difference;
    width: 4rem;
  }
  .dpkCursor.hover-img {
    background-size: cover;
    border: none;
    border-radius: 0;
    height: 10rem;
    mix-blend-mode: normal;
    width: 10rem;
  }
  .dpkCursor.star {
    font-size: 5rem;
    height: 10rem;
    width: 10rem;
  }


//---------------------------------------------
// 3. Components
//---------------------------------------------

@import "components/slider";