//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

// $body-color: #0D0D0D; // The default text color

$white:#FFF;

$primary:    #FFF;
$secondary:  #000;
$success:    #262626;
$info:       #0dcaf0;
$warning:    #ffc107;
$danger:     #dc3545;
$light:      #FFF;
$dark:       #000;

// $light:      #EFEEEA;
// $dark:       #28282A;


//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

//---------------------------------------------
// 5. Bootstrap Display Font sizes
//---------------------------------------------
$font-weight-bold:            900;
$line-height-base:            1.2;



$display-font-sizes: (
  1: 9rem,
  2: 8.5rem,
  3: 8rem,
  4: 7.5rem,
  5: 6rem,
  6: 5.5rem
);

//---------------------------------------------
// 6. Bootstrap Margin Negative Values
//---------------------------------------------


$enable-negative-margins: true;


$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$macouleur :#fc4d41e0;

$transition-collapse:         height 1s ease;
$transition-collapse-width:   width 1s ease;


//---------------------------------------------
// 7. navbar dark
//---------------------------------------------

$navbar-dark-color:                 none;
$navbar-dark-hover-color:           none;
$navbar-dark-active-color:          none;
// $navbar-dark-disabled-color:        rgba($white, .25);
// $navbar-dark-icon-color:            $navbar-dark-color;
// $navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
// $navbar-dark-toggler-border-color:  rgba($white, .1);
$navbar-dark-brand-color:           none;
$navbar-dark-brand-hover-color:     none;

// $navbar-padding-y:                  $spacer * .5;
// $navbar-padding-x:                  null;

// $navbar-nav-link-padding-x:         .5rem;

// $navbar-brand-font-size:            $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
// $nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2;
// $navbar-brand-height:               $navbar-brand-font-size * $line-height-base;
// $navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5;
// $navbar-brand-margin-end:           1rem;

//---------------------------------------------
// 7. thumbnail
//---------------------------------------------

// $thumbnail-padding:                 .25rem;
// $thumbnail-bg:                      var(--#{$prefix}body-bg);
$thumbnail-border-width: 0;
// $thumbnail-border-color:            var(--#{$prefix}border-color);
// $thumbnail-border-radius:           var(--#{$prefix}border-radius);
$thumbnail-box-shadow: none;

//---------------------------------------------
// 8. Carousel
//---------------------------------------------


$carousel-caption-width:             100%;
$carousel-caption-color:             $light;
$carousel-caption-padding-y:         1.25rem;
$carousel-caption-spacer:            1.25rem;